<template>
  <div>
    <!-- assessment_switches_type -->

    <div class="container-buttons-report mb-1">
      <div class="align-content-end"></div>
      <div>
        <div class="noprint">
          <div class="d-flex ml-auto">
            <slot
              v-if="
                assessment_range_report &&
                assessment_range_report.assessments &&
                assessment_range_report.evaluatees &&
                assessment_range_report.achievement_ranges
              "
              name="student_status"
            >
            </slot>
            <div
              class="d-flex"
              v-if="
                assessment_range_report && assessment_range_report.assessments
              "
            >
              <div class="ml-auto mr-0 d-flex">
                <strong
                  class="mr-2 align-content-center"
                  style="font-size: 10pt"
                >
                  FORMATIVAS
                </strong>
                <b-form-checkbox
                  class="align-content-center"
                  v-model="assessment_switches_type"
                  switch
                >
                  <strong style="font-size: 10pt">SUMATIVAS</strong>
                </b-form-checkbox>
              </div>
            </div>
            <GenericButtonDownload
              v-if="
                !is_loading &&
                assessment_range_report &&
                assessment_range_report.assessments &&
                assessment_range_report.evaluatees
              "
              tooltip_text="Reporte XLSX"
              :click_button="DownloadExcel"
              :class="`ml-1`"
              :icon_excel="true"
            ></GenericButtonDownload>
          </div>
        </div>
      </div>
    </div>

    <!-- b-table -->
    <b-skeleton-table
      v-if="is_loading"
      :rows="9"
      :columns="10"
      :table-props="{ bordered: true, striped: true }"
    ></b-skeleton-table>
    <div class="table-wrapper">
      <b-table-simple
        bordered
        no-border-collapse
        small
        responsive
        v-if="
          !is_loading &&
          assessment_range_report &&
          assessment_range_report.assessments &&
          assessment_range_report.evaluatees
        "
        class="table-assesment"
      >
        <b-thead>
          <b-tr>
            <b-th
              class="text-center align-middle primary-header"
              :colspan="8 + assessment_range_report.assessments.length"
            >
              <div class="d-flex align-items-center justify-content-between">
                <span class="text-center mx-auto">
                  REPORTE DE ASSESSMENT POR
                  {{
                    $getVisibleNames(
                      "mesh.competence",
                      true,
                      "Competencias"
                    ).toUpperCase()
                  }}
                  DEL PERFIL DE EGRESO
                  <template v-if="selected_student_status != 2">
                    <InfoTooltip
                      :id="`table-student-status`"
                      class="mr-2"
                      tooltip_text=""
                    ></InfoTooltip>
                    <b-popover
                      :target="`table-student-status`"
                      triggers="hover"
                      placement="top"
                      boundary="viewport"
                    >
                      <template #title>
                        <div>
                          Este cuadro aplica solo para estudiantes
                          <strong>"En Régimen"</strong>
                        </div>
                      </template>
                      <strong>
                        <div>(✓) Cursada</div>
                        <div>(-) En Proceso</div>
                        <div>(x) Por Cursar</div>
                      </strong>
                    </b-popover>
                  </template>
                </span>
                <VisibleColumns
                  v-if="displayList.length > 0"
                  :id_button="`col-display-1-${main_assessments.join(',')}`"
                  :tooltip_text="''"
                >
                  <template #popover-title> Visualizar columnas </template>
                  <template #popover-content>
                    <template v-if="assessment_range_report != null">
                      <div class="visible_columns">
                        <template v-for="display in displayList">
                          <div
                            :key="'index' + display.index + 'id' + display.id"
                          >
                            <div class="noprint">
                              <b-form-checkbox
                                :id="`column-check-${display.index}`"
                                name="checkbox-1"
                                v-model="display.visible"
                              >
                                {{ display.title }}
                                {{ display.order ? display.order : "" }}
                              </b-form-checkbox>
                            </div>
                          </div>
                        </template>
                      </div>
                    </template>
                  </template>
                </VisibleColumns>
              </div>
            </b-th>
          </b-tr>
          <b-tr>
            <b-th
              class="text-center align-middle secondary-header"
              v-if="
                displayList.some((x) => $equals(x.title, 'N°') && x.visible)
              "
            >
              N°
            </b-th>
            <b-th
              class="text-center align-middle secondary-header"
              v-if="
                displayList.some(
                  (x) => $equals(x.title, 'APELLIDO') && x.visible
                )
              "
            >
              APELLIDO
            </b-th>
            <b-th
              class="text-center align-middle secondary-header"
              v-if="
                displayList.some((x) => $equals(x.title, 'NOMBRE') && x.visible)
              "
            >
              NOMBRE
            </b-th>
            <b-th
              class="text-center align-middle secondary-header"
              v-if="
                displayList.some(
                  (x) => $equals(x.title, 'IDENTIFICADOR') && x.visible
                )
              "
            >
              IDENTIFICADOR
            </b-th>
            <b-th
              class="text-center align-middle secondary-header"
              v-if="
                displayList.some(
                  (x) => $equals(x.title, 'AÑO DE INGRESO') && x.visible
                )
              "
            >
              AÑO DE INGRESO
            </b-th>
            <b-th
              class="text-center align-middle secondary-header"
              v-if="
                displayList.some(
                  (x) =>
                    $equals(
                      x.title,
                      $getVisibleNames(
                        'mesh.egressprofile',
                        false,
                        'Perfil de Egreso'
                      ).toUpperCase()
                    ) && x.visible
                )
              "
            >
              {{
                $getVisibleNames(
                  "mesh.egressprofile",
                  false,
                  "Perfil de Egreso"
                ).toUpperCase()
              }}
            </b-th>
            <b-th
              class="text-center align-middle secondary-header"
              :id="`student_status_header`"
              v-if="
                displayList.some(
                  (x) => $equals(x.title, 'EN RÉGIMEN') && x.visible
                ) && selected_student_status == 3
              "
            >
              EN RÉGIMEN
            </b-th>
            <template
              v-for="assessment_label in assessment_range_report.assessments"
            >
              <b-th
                v-if="
                  displayList.some(
                    (x) =>
                      $equals(
                        `${x.id} ${x.title}`,
                        `${
                          assessment_label.id
                        } ${assessment_label.assessment_type_name.toUpperCase()}`
                      ) && x.visible
                  )
                "
                class="text-center align-middle secondary-header"
                :key="`header-assessment-${assessment_label.id}`"
                :id="`header-assessment-top-${assessment_label.id}`"
                v-b-tooltip.v-secondary.noninteractive="
                  `${
                    selected_student_status == 2
                      ? assessment_label.full_sentence
                      : ''
                  }`
                "
              >
                {{ assessment_label.assessment_type_short_name.toUpperCase() }}
                {{ assessment_label.order }}
                <span v-if="assessment_label.weighing">
                  {{
                    assessment_label.weighing ? assessment_label.weighing : "0"
                  }}%
                </span>
                (%)
              </b-th>
            </template>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(
              evaluatee_assessment, index
            ) in assessment_range_report.evaluatees"
            :key="`row-${evaluatee_assessment.id}-${evaluatee_assessment.section_id}-${index}`"
          >
            <!-- N° -->
            <b-td
              v-if="
                displayList.some((x) => $equals(x.title, 'N°') && x.visible)
              "
              class="text-center aling-middle"
            >
              {{ index + 1 }}
            </b-td>
            <!-- APELLIDO -->
            <b-td
              v-if="
                displayList.some(
                  (x) => $equals(x.title, 'APELLIDO') && x.visible
                )
              "
              class="text-left aling-middle"
            >
              {{ evaluatee_assessment.last_name }}</b-td
            >
            <!-- NOMBRE -->
            <b-td
              v-if="
                displayList.some((x) => $equals(x.title, 'NOMBRE') && x.visible)
              "
              class="text-left aling-middle"
            >
              {{ evaluatee_assessment.first_name }}</b-td
            >
            <!-- IDENTIFICADOR -->
            <b-td
              v-if="
                displayList.some(
                  (x) => $equals(x.title, 'IDENTIFICADOR') && x.visible
                )
              "
              class="text-center aling-middle"
            >
              {{ evaluatee_assessment.identifier }}
            </b-td>
            <!-- AÑO DE INGRESO -->
            <b-td
              v-if="
                displayList.some(
                  (x) => $equals(x.title, 'AÑO DE INGRESO') && x.visible
                )
              "
              class="text-center aling-middle"
            >
              {{ evaluatee_assessment.cohort }}</b-td
            >
            <!-- PERFIL DE EGRESO -->
            <b-td
              v-if="
                displayList.some(
                  (x) =>
                    $equals(
                      x.title,
                      $getVisibleNames(
                        'mesh.egressprofile',
                        false,
                        'Perfil de Egreso'
                      ).toUpperCase()
                    ) && x.visible
                )
              "
              class="text-left aling-middle"
            >
              {{ evaluatee_assessment.egress_profile }}
            </b-td>
            <!-- EN RÉGIMEN -->
            <b-td
              v-if="
                displayList.some(
                  (x) => $equals(x.title, 'EN RÉGIMEN') && x.visible
                ) && selected_student_status
              "
              class="text-center aling-middle"
            >
              {{ evaluatee_assessment.evaluatee_is_on_track ? "Sí" : "No" }}
            </b-td>
            <template
              v-for="(assessment, index2) in evaluatee_assessment.assessments"
            >
              <template
                v-if="
                  displayList.some(
                    (x) =>
                      $equals(
                        `${x.id} ${x.title}`,
                        `${
                          assessment.assessment_id
                        } ${assessment.assessment_type_name.toUpperCase()}`
                      ) && x.visible
                  )
                "
              >
                <template v-if="assessment_switches_type">
                  <b-td
                    :key="`row-assessment-sumative-${evaluatee_assessment.id}-${assessment.id}-${index2}`"
                    :style="`background-color:${assessment.summative_assessment_color} !important`"
                  >
                    <span v-if="assessment.summative_assessment >= 0">
                      {{ assessment.summative_assessment | Round }}
                    </span>
                    <span v-else> {{ assessment.summative_assessment }}</span>
                  </b-td>
                </template>
                <template v-else>
                  <b-td
                    :key="`row-assessment-formative-${evaluatee_assessment.id}-${assessment.id}-${index2}`"
                    :style="`background-color:${assessment.formative_assessment_color} !important`"
                  >
                    <span v-if="assessment.formative_assessment >= 0">
                      {{ assessment.formative_assessment | Round }}
                    </span>
                    <span v-else> {{ assessment.formative_assessment }}</span>
                  </b-td>
                </template>
              </template>
            </template>
          </b-tr>
          <b-tr v-if="assessment_range_report.evaluatees.length == 0">
            <b-td :colspan="displayList.length">
              No hay datos que mostrar
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <div
      class="d-flex"
      v-if="assessment_range_report && assessment_range_report.assessments"
    >
      <div class="ml-auto mr-0 d-flex">
        <strong class="mr-2 align-content-center" style="font-size: 10pt">
          FORMATIVAS
        </strong>
        <b-form-checkbox v-model="assessment_switches_type" switch>
          <strong style="font-size: 10pt">SUMATIVAS</strong>
        </b-form-checkbox>
      </div>
    </div>
    <div class="table-wrapper">
      <b-table-simple
        bordered
        no-border-collapse
        small
        responsive
        v-if="
          assessment_range_report &&
          assessment_range_report.assessments &&
          assessment_range_report.evaluatees &&
          assessment_range_report.achievement_ranges
        "
        class="table-assesment-range-table"
      >
        <b-thead>
          <b-tr>
            <b-th
              class="primary-header"
              :colspan="assessment_range_report.assessments.length + 2"
              >RANGOS DE LOGROS
            </b-th>
          </b-tr>
          <b-tr>
            <b-th class="secondary-header">NOMBRE</b-th>
            <b-th class="secondary-header range-column">RANGO (%)</b-th>
            <b-th
              class="text-center align-middle secondary-header"
              v-for="assessment_label in assessment_range_report.assessments"
              :key="`header-assessment-${assessment_label.id}`"
              :id="`header-assessment-bottom-${assessment_label.id}`"
              v-b-tooltip.v-secondary.noninteractive="
                `${
                  selected_student_status == 2
                    ? assessment_label.full_sentence
                    : ''
                }`
              "
            >
              {{ assessment_label.assessment_type_short_name.toUpperCase() }}
              {{ assessment_label.order }}
              <span v-if="assessment_label.weighing">
                {{ assessment_label.weighing }}%
              </span>
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="achievement_range in assessment_range_report.achievement_ranges"
            :key="`row-${achievement_range.id}`"
          >
            <b-td class="text-center aling-middle">{{
              achievement_range.name
            }}</b-td>
            <b-td
              class="text-center aling-middle"
              :style="`background-color:${achievement_range.color} !important`"
            >
              <div>
                {{ achievement_range.start_achievement }}
                <span v-if="achievement_range.include_start_achievement"
                  >&le;</span
                >
                <span v-else>&lt;</span>
                x
                <span v-if="achievement_range.include_finish_achievement"
                  >&le;</span
                >
                <span v-else>&lt;</span>
                {{ achievement_range.finish_achievement }}
              </div>
            </b-td>
            <b-td
              v-for="assessment_range in assessment_range_report.assessments"
              :key="`row-${achievement_range.id}columns-${assessment_range.id}`"
            >
              {{
                countAchievementRange(
                  achievement_range.id,
                  assessment_range.achievement_ranges
                )
              }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-table-simple
        bordered
        no-border-collapse
        small
        responsive
        v-if="
          assessment_range_report &&
          assessment_range_report.assessments &&
          assessment_range_report.evaluatees &&
          assessment_range_report.achievement_ranges
        "
        class="table-assesment-print-table"
      >
        <b-thead>
          <b-th class="text-center align-middle primary-header">
            Leyenda de
            {{
              $getVisibleNames("mesh.competence", true, "Competencias")
            }}</b-th
          >
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="assessment in assessment_range_report.assessments"
            :key="`print-table-${assessment.id}`"
          >
            <b-td class="text-left"
              >{{ assessment.order }} {{ assessment.full_sentence }}</b-td
            >
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <GenericGraphic
      v-if="assessmentOptions.length > 0"
      :labels="chartLabels"
      :datasets="chartDatasets"
      :legend_labels="legendLabels"
      :title_graphic="`REPORTE DE ASSESSMENT POR ${$getVisibleNames(
        'mesh.competence',
        true,
        'Competencias'
      ).toUpperCase()} DEL ${$getVisibleNames(
        'mesh.egressprofile',
        false,
        'Perfil de Egreso'
      ).toUpperCase()}`"
      :total_students="totalStudents"
      :label_y_axis="`N° de Estudiantes`"
      :title_table_circular="`Assessment`"
    >
      <template #selector>
        <b-form-select
          id="assessmentSelector"
          v-model="selected_assessment"
          :options="assessmentOptions"
          @change="updateAssessment"
          class="select-graphic-type"
        ></b-form-select>
      </template>
    </GenericGraphic>
    <!-- popover -->
    <template v-for="assessment_label in assessment_range_report.assessments">
      <div
        :key="`popover-${assessment_label.id}`"
        v-if="selected_student_status != 2"
      >
        <b-popover
          :target="`header-assessment-top-${assessment_label.id}`"
          triggers="hover"
          placement="top"
          boundary="viewport"
        >
          <template #title>
            <div
              class="truncated-text"
              v-b-tooltip.v-secondary.noninteractive="
                assessment_label.full_sentence
              "
            >
              <span v-if="assessment_label.order">
                {{ assessment_label.order }}
              </span>
              {{ assessment_label.full_sentence }}
            </div>
          </template>
          <!-- body -->
          <div class="visible_columns">
            <div
              v-for="matter in assessment_label.competence_matters_data"
              :key="`b-popover-body${assessment_label.id}-${matter.matter_id}`"
            >
              <strong>{{ matter.state }}</strong>
              {{ matter.matter_name }}
            </div>
          </div>
        </b-popover>
        <b-popover
          :target="`header-assessment-bottom-${assessment_label.id}`"
          triggers="hover"
          placement="top"
          boundary="viewport"
        >
          <template #title>
            <div
              class="truncated-text"
              v-b-tooltip.v-secondary.noninteractive="
                assessment_label.full_sentence
              "
            >
              <span v-if="assessment_label.order">
                {{ assessment_label.order }}
              </span>
              {{ assessment_label.full_sentence }}
            </div>
          </template>
          <!-- body -->
          <div class="visible_columns">
            <div
              v-for="matter in assessment_label.competence_matters_data"
              :key="`b-popover-body${assessment_label.id}-${matter.matter_id}`"
            >
              <strong>{{ matter.state }}</strong>
              {{ matter.matter_name }}
            </div>
          </div>
        </b-popover>
      </div>
    </template>
    <template
      v-if="
        assessment_range_report &&
        assessment_range_report.extra_data &&
        assessment_range_report.extra_data.matters_data.length > 0 &&
        selected_student_status == 3
      "
    >
      <b-popover
        :target="`student_status_header`"
        triggers="hover"
        placement="top"
        boundary="viewport"
      >
        <!-- body -->
        <div class="visible_columns">
          <div
            v-for="matter in assessment_range_report.extra_data.matters_data"
            :key="`b-popover-extra_data-${matter.matter_id}`"
          >
            <strong>{{ matter.state }}</strong>
            {{ matter.matter_name }}
          </div>
        </div>
      </b-popover>
    </template>
  </div>
</template>

<script>
// import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "AssessmentRangeTable",
  components: {
    GenericButtonDownload: () =>
      import("@/components/reusable/Buttons/GenericButtonDownload"),
    VisibleColumns: () =>
      import("@/components/reusable/Buttons/VisibleColumns"),
    InfoTooltip: () => import("@/components/reusable/InfoTooltip"),
    GenericGraphic: () => import("@/components/reusable/GenericGraphic"),
  },
  props: {
    main_assessments: {
      type: Array,
      required: true,
    },
    achievement_range_group: {
      type: [Number, null],
      required: false,
      default: null,
    },
    selected_student_status: {
      type: Number,
      default: 1,
    },
    egress_profile: {
      type: Object,
    },
    campuses_: {
      type: Array,
    },
    cohorts_: {
      type: Array,
    },
    cycles_: {
      type: Array,
    },
  },
  data() {
    return {
      is_loading: false, // Variable para mostrar "skeletons"
      assessment_range_report: [],
      displayList: [],
      assessment_switches_type: true,
      selected_assessment: "compare_all", // Variable para guardar assessment del selector.
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
    }),
    assessmentOptions() {
      if (!this.assessment_range_report?.assessments) return [];

      const options = this.assessment_range_report.assessments.map((x) => ({
        value: x.id,
        text: `${x.assessment_type_short_name} ${x.order || ""}`.trim(),
      }));

      const firstAssessmentType =
        this.assessment_range_report.assessments[0]
          ?.assessment_type_short_name || "";

      // Añadir opción "Comparar todos"
      options.push({
        value: "compare_all",
        text: `Ver todas las ${firstAssessmentType}`,
      });

      return options;
    },
    totalStudents() {
      return this.assessment_range_report?.evaluatees?.length || 0;
    },
    // Etiquetas del gráfico (eje X)
    chartLabels() {
      if (
        !this.assessment_range_report ||
        !this.assessment_range_report.assessments
      ) {
        return [];
      }
      if (this.selected_assessment == "compare_all") {
        return this.assessment_range_report.assessments.map(
          (assessment) =>
            `${assessment.assessment_type_short_name} ${assessment.order}`
        );
      }

      // Para la opción individual, mostrar el nombre del assessment y el order
      const selected = this.assessment_range_report.assessments.find(
        (x) => x.id == this.selected_assessment
      );
      return selected
        ? [`${selected.assessment_type_short_name} ${selected.order}`]
        : [];
    },
    // Datos del gráfico (eje Y)
    chartDatasets() {
      if (
        !this.assessment_range_report?.achievement_ranges ||
        !this.selected_assessment
      ) {
        return [];
      }

      // Si se selecciona "Comparar todos"
      if (this.selected_assessment == "compare_all") {
        const assessmentsToCompare = this.assessment_range_report.assessments;

        // Crear un dataset por cada achievement_range
        return this.assessment_range_report.achievement_ranges.map((range) => ({
          data: assessmentsToCompare.map((assessment) => {
            const matchingRange = assessment.achievement_ranges.find(
              (x) => x.achievement_range_id == range.id
            );
            return matchingRange
              ? this.assessment_switches_type
                ? matchingRange.summative_count
                : matchingRange.formative_count
              : 0;
          }),
          backgroundColor: range.color,
        }));
      }

      // Si se selecciona una opción individual
      const selected = this.assessment_range_report.assessments.find(
        (x) => x.id == this.selected_assessment
      );
      if (!selected) return [];

      // Crear un dataset por cada achievement_range para el assessment seleccionado
      return this.assessment_range_report.achievement_ranges.map((range) => {
        const matchingRange = selected.achievement_ranges.find(
          (x) => x.achievement_range_id == range.id
        );
        return {
          label: range.name,
          data: [
            matchingRange
              ? this.assessment_switches_type
                ? matchingRange.summative_count
                : matchingRange.formative_count
              : 0,
          ],
          backgroundColor: range.color,
        };
      });
    },
    legendLabels() {
      if (
        this.assessment_range_report &&
        this.assessment_range_report.achievement_ranges
      ) {
        // Devuelve los nombres de los rangos
        return this.assessment_range_report.achievement_ranges.map((x) => ({
          label: x.name,
          color: x.color,
        }));
      }
      return [];
    },
  },
  methods: {
    updateAssessment(selectedId) {
      this.selected_assessment = selectedId;
    },
    DownloadExcel() {
      this.$restful
        .Post(`assessment2/excel_report_generic_achievement_report/`, {
          depth_level: 0,
          report_type: 2,
          egress_profile: this.egress_profile.id,
          cohort_year: this.cohorts_[0].cohort,
          cycle_filters: this.cycles_ ? this.cycles_.map((x) => x.id) : null,
          campus_filters: this.campuses_
            ? this.campuses_.map((x) => x.id)
            : null,
          main_assessments: this.main_assessments,
          achievement_range_group: this.achievement_range_group,
          student_status: this.selected_student_status,
        })
        .then((response) => {
          if (response && response.file_url) {
            window.open(response.file_url, "_blank");
          }
        });
    },
    getAssessmentRange() {
      if (this.main_assessments.length > 0) {
        this.is_loading = true;
        this.displayList = [];
        this.$restful
          .Post(`/assessment2/generic_achievement_report/`, {
            depth_level: 0,
            report_type: 2,
            egress_profile: this.egress_profile.id,
            cohort_year: this.cohorts_[0].cohort,
            cycle_filters: this.cycles_ ? this.cycles_.map((x) => x.id) : null,
            campus_filters: this.campuses_
              ? this.campuses_.map((x) => x.id)
              : null,
            main_assessments: this.main_assessments,
            achievement_range_group: this.achievement_range_group,
            student_status: this.selected_student_status,
          })
          .then((response) => {
            this.assessment_range_report = response;
            this.completeDisplayList(response);
            this.$swal.close();
            this.is_loading = false;
          })
          .catch(() => {
            this.is_loading = false;
            this.$swal.close();
            this.$swal.fire(
              "Error",
              "Hubo un problema al generar el reporte.",
              "error"
            );
          });
      }
    },
    completeDisplayList(response) {
      this.displayList = [
        {
          index: 0,
          id: "N°",
          title: "N°",
          visible: true,
        },
        {
          index: 1,
          id: "AÑO DE INGRESO",
          title: "AÑO DE INGRESO",
          visible: true,
        },
        {
          index: 2,
          id: "IDENTIFICADOR",
          title: "IDENTIFICADOR",
          visible: true,
        },
        {
          index: 3,
          id: "APELLIDO",
          title: "APELLIDO",
          visible: true,
        },
        {
          index: 4,
          id: "NOMBRE",
          title: "NOMBRE",
          visible: true,
        },
        {
          index: 5,
          id: this.$getVisibleNames(
            "mesh.egressprofile",
            false,
            "Perfil de Egreso"
          ).toUpperCase(),
          title: this.$getVisibleNames(
            "mesh.egressprofile",
            false,
            "Perfil de Egreso"
          ).toUpperCase(),
          visible: false,
        },
      ];
      if (this.selected_student_status == 3)
        this.displayList.push({
          index: 6,
          id: "EN RÉGIMEN",
          title: "EN RÉGIMEN",
          visible: true,
        });

      let display_index = this.displayList.length;
      if (response.assessments && response.assessments.length > 0)
        response.assessments.forEach((element) => {
          this.displayList.push({
            index: display_index,
            id: element.id,
            title: `${element.assessment_type_name.toUpperCase()}`,
            order: element.order,
            visible: true,
          });
          display_index += 1;
        });
    },
    countAchievementRange(achievement_range_id, achievement_range_list) {
      const achivement = achievement_range_list.find(
        (x) => x.achievement_range_id == achievement_range_id
      );
      if (achivement) {
        if (this.assessment_switches_type) return achivement.summative_count;
        else return achivement.formative_count;
      } else return "-";
    },
  },
  created() {
    this.getAssessmentRange();
  },
};
</script>

<style scoped>
.container-buttons-report {
  display: flex;
  justify-content: right;
}
.primary-header {
  background-color: var(--kl-menu-color) !important;
  color: white;
}
.secondary-header {
  background: #d0cece !important;
}
.col-display-1,
.col-display-2 {
  min-width: 200px;
  margin-left: 2em;
  background-color: var(--kl-primary-button-color) !important;
  color: #fff;
}
.col-display-1:hover,
.col-display-2:hover {
  background-color: var(--kl-primary-button-hover-color) !important;
  transition: all 0.3s;
}
.truncated-text {
  white-space: nowrap; /* Evita el salto de línea */
  overflow: hidden; /* Oculta el contenido que se desborda */
  text-overflow: ellipsis; /* Agrega "..." al final */
  width: 100%; /* Asegura que no sobrepase el contenedor */
}
.visible_columns {
  max-height: 300px;
  overflow: auto;
}

@media (max-width: 768px) {
  .table-wrapper {
    overflow-x: auto;
  }

  .table-assesment-range-table {
    width: 100%;
    min-width: 600px;
    border-collapse: collapse; /* Evita que las celdas se sobrepongan */
  }
  .table-assesment {
    width: 100%;
    min-width: 800px;
    border-collapse: collapse; /* Evita que las celdas se sobrepongan */
  }
}
/* .table-assesment-print-table {
  display: none;
} */

@media print {
  .table-assesment-print-table {
    display: block;
  }
}
</style>

